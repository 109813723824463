import { Subscription } from 'rxjs';
import { Location } from '@angular/common';
import { formatDate } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  MatDialog,
} from '@angular/material/dialog';
import { finalize, map, take } from 'rxjs/operators';
import { ConfirmationDialogComponent } from 'src/app/shared/components/confirmation-dialog/confirmation-dialog.component';
import CATEGORIES from "src/assets/json/CATEGORIES.json";
import { GraphqlService } from 'src/app/core/graphql/graphql.service';
import { Obs } from 'src/app/shared/models/obs.model';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/core/core.state';
import { obsRep } from 'src/app/shared/models/obsRep.model';
import { TranslateService } from '@ngx-translate/core';
import { selectRecordBirthdate, selectRecordId, selectRecordNumber } from 'src/app/core/record/record.selectors';
import { SharedService } from 'src/app/shared/services/shared.service';
import { setRecord, validateObs } from 'src/app/core/record/record.actions';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  templateUrl: './draft-mode.component.html',
  styleUrls: ['./draft-mode.component.scss'],
})
export class DraftModeComponent implements OnInit, OnDestroy {
  filteredQues = [];

  isLoading = true;

  deleteObsLoading = false;

  isSaving: boolean;

  title: string;

  sub: Subscription;

  sansRepCount: number;

  age: number;

  obsId: string;

  recordId: string;

  recordNumber: number;

  isScrolled: boolean;

  observation: Obs;

  answers: { [answerCode: string]: obsRep };

  isFilled = false;

  constructor(
    private _translate: TranslateService,
    private route: ActivatedRoute,
    private _router: Router,
    private _graphql: GraphqlService,
    private _sharedService: SharedService,
    private _store: Store<AppState>,
    public dialog: MatDialog
  ) { }


  public get filteredCats() {
    const filteredCats = [...new Set(this.filteredQues.filter(element => element.length > 0).map(element => element[0].category1Rk))]
    return CATEGORIES.filter(cat => filteredCats.includes(cat.value))
  }

  ngOnInit(): void {
    this.obsId = this.route.snapshot.paramMap.get('obsId');
    this._store.select(selectRecordNumber).pipe(take(1)).subscribe(number => this.recordNumber = number)
    this._store.select(selectRecordId).pipe(take(1)).subscribe(id => this.recordId = id)
    this._graphql.getObs(this.obsId)
      .pipe(
        take(1),
        map(({ data }: any) => data.observation),
      ).subscribe((obs) => {
        this.observation = obs;

        this._store.select(selectRecordBirthdate)
          .pipe(
            take(1),
            finalize(() => this.isLoading = false)
          )
          .subscribe(birthdate => {
            this.age = this._sharedService.getAgeFromBirthdate(birthdate) - this._sharedService.getAgeFromBirthdate(this.observation.date)

          },
            (error) => console.log(error))

        const date = formatDate(
          obs.date,
          'longDate',
          'fr-FR'
        );
        this.title = this._translate.instant("editObsTitle") + date;
      },
        (error) => {
          this.isLoading = false;
          console.log(error);

        })
  }

  async onConfirmFinish() {

    const learningLate = ([].concat(...this.filteredQues)).filter(ques => this.answers[ques.answerCode].level < 5 && ques.direction == 'until' && ques.endMonth < this.age)
    const learningEarly = ([].concat(...this.filteredQues)).filter(ques => this.answers[ques.answerCode].level == 5 && ques.endMonth > this.age)
    this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: this._translate.instant("finishObs"),
        learningEarly,
        learningLate,
        msg: `${this._translate.instant("confirmFinishObs")}`,
      },
    })
      .afterClosed()
      .pipe(
        take(1)
      ).subscribe(result => {
        if (result.data) {
          this.onFinishObs();
        }

      });
  }


  onFinishObs() {
    this.isSaving = true;
    this._graphql.updateObsValidated(this.observation.id, true)
      .pipe(
        take(1),
        finalize(() => this.isSaving = false))
      .subscribe(() => {
          this._sharedService.makeToast(this._translate.instant("finalizeObsSuccess"), '', 'success');
          this._store.dispatch(validateObs({ id: this.obsId }))
        this.onViewObs(this.observation.id);
      },
        (err) => {
          this._sharedService.makeToast(this._translate.instant("error"), err, 'danger');
        })
  }

  onViewObs(obsId) {
    this._router.navigateByUrl(`/start/doc/${this.recordId}/observation/${obsId}`);
  }

  backToRecord() {
    this._router.navigateByUrl(`/start/doc/${this.recordId}`)
  }

  onScroll(ev) {
    if (ev.detail.currentY > 50) {
      this.isScrolled = true;
    } else {
      this.isScrolled = false;
    }
  }

  scrollTop(content) {
    content.scrollToTop(1500);
  }

  slidePrev(slides) {
    slides.slidePrev();
  }

  delete() {
    this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: this._translate.instant("deleteObs"),
        learningEarly: [],
        learningLate: [],
        msg: `${this._translate.instant("confirmDeleteObs")}`,


      },
    })
      .afterClosed()
      .pipe(
        take(1)
      ).subscribe(result => {
        if (result.data) {
          this.deleteObsLoading = true;
          this._graphql.deleteObs(this.observation.id)
            .pipe(
              take(1),
              finalize(() => this.deleteObsLoading = false))
            .subscribe(_ => {
              this.backToRecord();
              this._store.dispatch(setRecord({
                pendingObsId: null
              })
              )
              this._sharedService.makeToast(this._translate.instant("deleteObsSuccess"), '', 'success');
            })
        }

      });

  }

  slideNext(slides) {
    slides.slideNext();
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }
}
