<ion-progress-bar
  *ngIf="isLoading"
  type="indeterminate"
></ion-progress-bar>
<ion-content *ngIf="!isLoading">
  <ion-row style="position: relative; min-height: 100%">
    <ion-col
      style="margin-bottom: 80px"
      size="12"
      size-lg="10"
      offset-lg="1"
      size-xl="10"
      offset-xl="1"
    >
      <app-header
        [title]="translate.instant('record')+ ' ' + recordData.recordNumber"
        show="true"
        backTo="etab"
      ></app-header>

      <div style="position: absolute; right: 8px; z-index: 5">
        <mat-icon style="cursor: pointer;"
        color="accent"
          (click)="onTuto()"
        >help</mat-icon>
      </div>
      <div *ngIf="!isLoading">
        <div class="flex-grid">
          <app-card
            *ngIf="!isObsPaused"
            [isDisabled]="etabData?.status === 'inactive' || recordData.isArchived"
            color="#D8C4C4"
            title="newObs"
            icon="play_arrow"
            isParagraph="true"
            paragraph="noObs"
            button1="newObs"
            [loading]="createObsLoading"
            (button1Clicked)="onNewObservation()"
          ></app-card>
          <app-card
            *ngIf="isObsPaused"
            color="#D8C4C4"
            title="currentObs"
            icon="pause"
            isParagraph="true"
            paragraph="currentObsText"
            button1="resume"
            (button1Clicked)="onObservation(pendingObsId)"
          ></app-card>
          <app-card
            [isDisabled]="obsCounter == 0"
            color="#D8C4C4"
            title="synthesis"
            icon="assessment"
            button1="consult"
            isParagraph="true"
            paragraph="synthesisText"
            (button1Clicked)="onViewSynthese()"
          ></app-card>
        </div>
        <div class="flex-grid">
          <app-card
            [isDisabled]="obsCounter == 0"
            color="#D8C4C4"
            title="finishedObs"
            icon="preview"
            button1="{{translate.instant('finishedObs')}} ({{ obsCounter }})"
            isParagraph="true"
            paragraph="finishedObsText"
            (button1Clicked)="onViewFinishedObs()"
          ></app-card>
          <app-card
            color="#D8C4C4"
            title="recordDetails"
            icon="find_in_page"
            line1="{{translate.instant('recordNumber')}}{{ recordData.recordNumber }}"
            line2="{{translate.instant('monthYearBirth')}} : {{
              recordData.birthdate | date: 'MM.YYYY'
            }}"
            button1="archiveRecord"
            (button1Clicked)="onArchiveRecord()"
          ></app-card>
        </div>
      </div>
    </ion-col>
    <app-footer></app-footer>
  </ion-row>
</ion-content>
