import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import {  MatTableModule } from '@angular/material/table';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MatBadgeModule } from '@angular/material/badge';
import {
  MatDialogModule,
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { HighchartsChartModule } from 'highcharts-angular';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatChipsModule } from '@angular/material/chips';
import {  MatMenuModule } from '@angular/material/menu';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { SharedModule } from 'src/app/shared/shared.module';
import { MatTabsModule } from '@angular/material/tabs';
import { FiltreComponent } from './observations/modals/filtre-age/filtre.component';
import { NewObservationComponent } from './observations/modals/new-obs/new-observation.component';
import { PDFComponent } from './observations/other/pdf/pdf.component';
import { BasicBarComponent } from './observations/charts/basic-bar/basic-bar.component';
import { SpiderChartComponent } from './observations/charts/spider-chart/spider-chart.component';
import { QuestionHistoryComponent } from './observations/modals/question-history/question-history.component';
import { DraftModeComponent } from './observations/draft-mode/draft-mode.component';
import { ViewModeComponent } from './observations/view-mode/view-mode.component';
import { TableComponent } from './observations/other/table/table.component';
import { AssimilationChartComponent } from './observations/charts/assimilation-chart/assimilation-chart.component';
import { FiltresComponent } from './observations/other/filtres/filtres.component';
import { ObservationsListComponent } from './observations/modals/observations-list/observations-list.component';
import { SquareTocComponent } from './observations/other/square-toc/square-toc.component';
import { DossierComponent } from './record.component';
import { DossierRoutingModule } from './record-routing.module';
import { ArchiveRecordComponent } from './observations/modals/archive-record/archive-record.component';
import { SyntheseComponent } from './observations/synthese/synthese.component';
import { LayoutModule } from '@angular/cdk/layout';
import { PDFSyntheseComponent } from './observations/other/pdf-synthese/pdf-synthese.component';
import { provideEnvironmentNgxMask } from 'ngx-mask';

@NgModule({
    declarations: [
        DossierComponent,
        FiltreComponent,
        NewObservationComponent,
        PDFComponent,
        PDFSyntheseComponent,
        BasicBarComponent,
        SpiderChartComponent,
        QuestionHistoryComponent,
        DraftModeComponent,
        ViewModeComponent,
        TableComponent,
        AssimilationChartComponent,
        FiltresComponent,
        ObservationsListComponent,
        SquareTocComponent,
        ArchiveRecordComponent,
        SyntheseComponent,
    ],
    imports: [
        SharedModule,
        CommonModule,
        DossierRoutingModule,
        MatExpansionModule,
        MatSelectModule,
        MatGridListModule,
        MatTableModule,
        MatButtonModule,
        MatButtonToggleModule,
        FormsModule,
        MatCardModule,
        MatToolbarModule,
        MatBadgeModule,
        MatTooltipModule,
        MatIconModule,
        MatDialogModule,
        MatRadioModule,
        MatDatepickerModule,
        ReactiveFormsModule,
        MatNativeDateModule,
        MatFormFieldModule,
        MatInputModule,
        MatListModule,
        HighchartsChartModule,
        MatSlideToggleModule,
        MatMenuModule,
        MatChipsModule,
        MatCheckboxModule,
        MatTabsModule,
        LayoutModule,
    ],
    providers: [
        provideEnvironmentNgxMask(),
        { provide: MatDialogRef, useValue: {} },
        { provide: MAT_DIALOG_DATA, useValue: {} },
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class RecordModule {}
