import {
  Component,
  Input,
  ChangeDetectionStrategy,
  OnInit,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as Highcharts from 'highcharts';
import More from 'highcharts/highcharts-more';
import { obsRep } from 'src/app/shared/models/obsRep.model';

More(Highcharts);

@Component({
  selector: 'app-spider-chart',
  templateUrl: './spider-chart.component.html',
  styleUrls: ['./spider-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpiderChartComponent implements OnInit {

  @Input() data: { [answerCode: string]: obsRep };

  Highcharts: typeof Highcharts = Highcharts; // required
  chartRef: Highcharts.Chart;
  chartCallback: Highcharts.ChartCallbackFunction = (chart) => {
    this.chartRef = chart;
  }

  chartConstructor: string = 'chart'; // optional string, defaults to 'chart'

  chartOptions: Highcharts.Options = {};


  constructor(private _translate: TranslateService) { }

  refreshChart() {
    this.chartRef?.series[4].update({ name: this._translate.instant("assimilated"), type: "area" }, false);
    this.chartRef?.series[3].update({ name: this._translate.instant("integrating"), type: "area" }, false);
    this.chartRef?.series[2].update({ name: this._translate.instant("exercising"), type: "area" }, false);
    this.chartRef?.series[1].update({ name: this._translate.instant("nonAccess"), type: "area" }, false);
    this.chartRef?.series[0].update({ name: this._translate.instant("unconcerned"), type: "area" }, false);
    this.chartRef.setTitle({
      text: this._translate.instant("spiderChartTitle"),
      x: -80,
    }, {
      text: this._translate.instant("spiderChartSubTitle"),
      x: -80,
    },
      false);

    this.chartRef.xAxis[0].categories[0] = this._translate.instant('socialAffective');
    this.chartRef.xAxis[0].categories[1] = this._translate.instant('cognitive');
    this.chartRef.xAxis[0].categories[2] = this._translate.instant('language');
    this.chartRef.xAxis[0].categories[3] = this._translate.instant('motor');
    this.chartRef.xAxis[0].categories[4] = this._translate.instant('somatic');
    this.chartRef.tooltip.options.pointFormat = '{series.name}: {point.y}%, {point.ques} ' + this._translate.instant("questions")
    this.chartRef.redraw()
  }

  ngOnInit(): void {

    //get tthe total of answers by category. Will be used to calculate percentages for chartValues below
    const categoriesTotal = Object.keys(this.data).map(answerCode => answerCode.charAt(0)).reduce((accu, cat) => (accu[cat]++, accu), {
      'A': 0,
      'B': 0,
      'C': 0,
      'D': 0,
      'E': 0
    });

    //chartValues[X][Y] is a 2D array where X are the levels ("s'exercse", etc), and Y are the number of answer by category("assimilé",etc) for the x
    var chartValues = Object.keys(this.data)
      .reduce((accu, answerCode) => {
        accu[this.data[answerCode].level - 1][Object.keys(categoriesTotal).indexOf(answerCode.charAt(0))] += 1;
        return accu;
      }, [
        [0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0],
      ])//convert the result in percentage
      .map(levelByCat => levelByCat.map(
        (level, index) =>
          Object.values(categoriesTotal)[index] == 0
            ?
            0
            :
            100 * (level / (Object.values(categoriesTotal)[index]))))

    this.chartOptions = {
      ...this.chartOptions,
      plotOptions: {
        area: {
          fillOpacity: 0
        }
      },
      chart: {
        polar: true,
        type: 'line',
      },
      title: {
        text: this._translate.instant("spiderChartTitle"),
        x: -80,
      },
      subtitle: {
        text: this._translate.instant("spiderChartSubTitle"),
        x: -80,
      },
      xAxis: {
        categories: [
          this._translate.instant('socialAffective'),
          this._translate.instant('cognitive'),
          this._translate.instant('language'),
          this._translate.instant('motor'),
          this._translate.instant('somatic'),
        ],
        tickmarkPlacement: 'on',
        lineWidth: 0,
      },
      yAxis: {
        reversed: false,
        gridLineInterpolation: 'polygon',
        lineWidth: 0,
        min: 0,
        max: 100,
        labels: {
          enabled: false
        }
      },
      legend: {
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'middle',
        reversed: true,
        x: 8,
        y: -16,
        floating: false,
        backgroundColor:
          Highcharts.defaultOptions.legend.backgroundColor || 'white',
        borderColor: '#CCC',
        borderWidth: 0,
        shadow: false,
        symbolHeight: 20,
        symbolWidth: 20,
        symbolRadius: 0,
        itemMarginTop: 4,
        itemMarginBottom: 4,
        width: 160,
      },
      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 500,
            },
            chartOptions: {
              legend: {
                align: 'center',
                verticalAlign: 'bottom',
              },
              pane: {
                size: '70%',
              },
            },
          },
        ],
      },
      tooltip: {
        headerFormat: '<b>{point.x}</b><br/>',
        pointFormat: '{series.name}: {point.y}%, {point.ques} ' + this._translate.instant("questions"),
        valueDecimals: 2,
        // pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}',
      },
      series: [
        {
          name: this._translate.instant("unconcerned"),
          type: 'area',
          data: chartValues[0],
          pointPlacement: 'on',
          color: '#8085e9',
          visible: false,
        },
        {
          name: this._translate.instant("nonAccess"),
          type: 'area',
          data: chartValues[1],
          pointPlacement: 'on',
          color: '#f7a35c',
          visible: false,
        },
        {
          name: this._translate.instant("exercising"),
          type: 'area',
          data: chartValues[2],
          pointPlacement: 'on',
          color: '#90ed7d',
          visible: false,
        },
        {
          name: this._translate.instant("integrating"),
          type: 'area',
          data: chartValues[3],
          pointPlacement: 'on',
          color: '#000',
          visible: false,
        },
        {
          name: this._translate.instant("assimilated"),
          type: 'area',
          data: chartValues[4],
          pointPlacement: 'on',
          color: '#7cb5ec',
        },
      ],
      credits: {
        enabled: false,
      },
    };
    this._translate.onLangChange.subscribe(val => this.refreshChart())
  }

}
