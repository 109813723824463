<ion-row>
  <ion-col style="text-align: center" size="2"> </ion-col>

  <ion-col size="8">
    <h1 style="text-align: center">{{ "answersHist" | translate | capitalizeFirstLetter }}</h1>
    <ion-progress-bar *ngIf="isLoading" type="indeterminate"></ion-progress-bar>
  </ion-col>

  <ion-col style="text-align: end" size="2">
    <ion-button mat-dialog-close fill="clear">
      <mat-icon>close</mat-icon>
    </ion-button>
  </ion-col>
</ion-row>
<mat-dialog-content>
  <div *ngIf="history.length === 0" style="text-align: center; margin: 16px">
    {{ "noAnswer" | translate | capitalizeFirstLetter }}
  </div>
  <div *ngIf="history.length > 0">
    <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="mat-elevation-z0">
      <ng-container matColumnDef="Date">
        <th mat-header-cell *matHeaderCellDef style="text-align: left"></th>
        <td mat-cell style="width: 100px; text-align: left" *matCellDef="let element">
          {{ element.date | date: 'shortDate' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="Utilisateur">
        <th mat-header-cell *matHeaderCellDef></th>

        <td mat-cell *matCellDef="let element" style="width: 300px; text-align: left">
          <i>{{ "by" | translate | capitalizeFirstLetter }}: </i>{{ element.signature }}
        </td>
      </ng-container>
      <ng-container matColumnDef="Réflexion">
        <th style="text-align-last: center" mat-header-cell *matHeaderCellDef>
          {{ "unconcerned" | translate | capitalizeFirstLetter }}
        </th>

        <td style="width: 80px; text-align: center" mat-cell *matCellDef="let element">
          <div *ngIf="element.level === 1" style="position: relative">
            <div [ngClass]="{
                  catA: cat === 'A',
                  catB: cat === 'B',
                  catC: cat === 'C',
                  catD: cat === 'D',
                  catE: cat === 'E'
                }"></div>
          </div>
          <div *ngIf="element.level !== 1">
            <div class="step"></div>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="Non-accès">
        <th style="text-align-last: center" mat-header-cell *matHeaderCellDef>
          {{ "nonAccess" | translate | capitalizeFirstLetter }}
        </th>
        <td style="width: 80px; text-align: center" mat-cell *matCellDef="let element">
          <div *ngIf="element.level === 2">
            <div [ngClass]="{
                  catA: cat === 'A',
                  catB: cat === 'B',
                  catC: cat === 'C',
                  catD: cat === 'D',
                  catE: cat === 'E'
                }"></div>
          </div>
          <div *ngIf="element.level !== 2">
            <div class="step"></div>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="S'exerce">
        <th style="text-align-last: center" mat-header-cell *matHeaderCellDef>
          {{ "exercising" | translate | capitalizeFirstLetter }}
        </th>
        <td style="width: 80px; text-align: center" mat-cell *matCellDef="let element">
          <div *ngIf="element.level === 3">
            <div [ngClass]="{
                  catA: cat === 'A',
                  catB: cat === 'B',
                  catC: cat === 'C',
                  catD: cat === 'D',
                  catE: cat === 'E'
                }"></div>
          </div>
          <div *ngIf="element.level !== 3">
            <div class="step"></div>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="Intègre">
        <th style="text-align-last: center" mat-header-cell *matHeaderCellDef>
          {{ "integrating" | translate | capitalizeFirstLetter }}
        </th>
        <td style="width: 80px; text-align: center" mat-cell *matCellDef="let element">
          <div *ngIf="element.level === 4">
            <div [ngClass]="{
                  catA: cat === 'A',
                  catB: cat === 'B',
                  catC: cat === 'C',
                  catD: cat === 'D',
                  catE: cat === 'E'
                }"></div>
          </div>
          <div *ngIf="element.level !== 4">
            <div class="step"></div>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="Assimilé">
        <th style="text-align-last: center" mat-header-cell *matHeaderCellDef>
          {{ "assimilated" | translate | capitalizeFirstLetter }}
        </th>
        <td style="width: 80px; text-align: center" mat-cell *matCellDef="let element">
          <div *ngIf="element.level === 5">
            <div [ngClass]="{
                  catA: cat === 'A',
                  catB: cat === 'B',
                  catC: cat === 'C',
                  catD: cat === 'D',
                  catE: cat === 'E'
                }"></div>
          </div>
          <div *ngIf="element.level !== 5">
            <div class="step"></div>
          </div>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
      <tr mat-row *matRowDef="let element; columns: columnsToDisplay"></tr>
    </table>
  </div>
</mat-dialog-content>