<div style="padding: 24px;">
  <h1 mat-dialog-title>{{ "newObs" | translate | capitalizeFirstLetter }}</h1>
  <div mat-dialog-content>
    <form [formGroup]="form">
      <div style="display: flex; flex-direction: column; align-items: center; padding-top: 8px;">
        <mat-form-field appearance="outline" color="accent" style="width:100%;max-width: 360px">
          <mat-label>{{ "observerName" | translate | capitalizeFirstLetter }}</mat-label>
          <input matInput placeholder="entrez votre nom" formControlName="signature" required />
        </mat-form-field>
        <div style="display: flex; flex-direction:row;">
          <button mat-flat-button mat-dialog-close style="border-radius: 0px;">{{ "cancel" | translate |
            capitalizeFirstLetter }}</button>
          <button [disabled]="this.form.invalid" mat-flat-button mat-dialog-close (click)="onStart()"
            style="border-radius: 0px;" color="accent">
            {{ "start" | translate | capitalizeFirstLetter }}
          </button>
        </div>
      </div>
    </form>
  </div>
</div>