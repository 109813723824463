<ion-progress-bar *ngIf="isLoading" type="indeterminate"></ion-progress-bar>
<ion-content *ngIf="!isLoading" #page>
  <ion-row style="position: relative; min-height: 100%">
    <ion-col style="margin-bottom: 80px" size="12" size-lg="10" offset-lg="1" size-xl="10" offset-xl="1">
      <app-header title="{{ 'viewObsTitle' | translate | capitalizeFirstLetter }}{{ obs.date | date: 'longDate' }}">
      </app-header>
      <a [routerLink]="['/', 'start','doc', recordId]" mat-button color="accent"
        style="font-size: 18px; color:#FD3F92;">
        <mat-icon>arrow_left</mat-icon>{{ ("backToRecord" | translate | capitalizeFirstLetter) }} {{ recordNumber }}
      </a>
      <h1 style="text-align: center;">
        {{ obs.signature ?? "anonyme" }}
      </h1>
      <ion-row>
        <ion-col style="text-align: center" size="2"> </ion-col>

        <ion-col size="8">
          <div [ngClass]="{
            matTitleInit: !isScrolled,
            matTitleScrolled: isScrolled
          }">
            <app-pdf [answers]="answers" *ngIf="obs && !isScrolled" [filteredQues]="filteredQues" isObs="true"
              [min]="min | age | async" [max]="max | age | async" [signature]="obs.signature" [dateObs]="obs.date"
              [obsId]="obs.id" (isLoadingStarted)="onLoadingStarted()" (isLoadingEnded)="onLoadingEnded()"></app-pdf>
          </div>
        </ion-col>

      </ion-row>
      <div class="sticky" style="z-index: 1000;">
        <p style="margin: 5px;">{{ "recordNumber" | translate | capitalizeFirstLetter }}{{ recordNumber }}</p>
        <p style="margin: 5px;">{{ "monthYearBirth" | translate | capitalizeFirstLetter }}: {{ birthdate | date:
          'MM/yyyy' }}</p>
        <p style="margin: 5px;">{{ "gender" | translate | capitalizeFirstLetter }}: {{ gender + "Gender" | translate }}
        </p>
        <app-square-toc [isModal]="true" [content]="page" [id]="2" [filteredCats]="filteredCats">
        </app-square-toc>
      </div>
      <ion-row>
        <ion-col size="12" size-lg="10" offset-lg="1" size-md="12">
          <div class="sticky">
            <app-filtres *ngIf="true" [answers]="answers" [ageAtObs]="ageAtObs" [min]="min" [obsId]="obs.id" [max]="max"
              [isObsViewMode]="true" [selectedQuesRep]="selectedQuesRep" (filteredQuesEvent)="filteredQues = $event">
            </app-filtres>
          </div>

          <mat-expansion-panel class="mat-elevation-z0" expanded style="margin-top: 8px; border-radius: 0px">
            <mat-expansion-panel-header>
              <mat-panel-title> Graphiques </mat-panel-title>
            </mat-expansion-panel-header>

            <div style="text-align: -webkit-center">
              <button [disabled]="isStart" mat-icon-button style="
                position: absolute;
                top: 50%;
                left: 0px;
                font-size: 25px;
                z-index: 2;
              " (click)="slidePrev()">
                <mat-icon>arrow_back_ios</mat-icon>
              </button>
              <button [disabled]="isEnd" mat-icon-button style="
                position: absolute;
                top: 50%;
                right: 0px;
                font-size: 25px;
                z-index: 2;
              " (click)="slideNext()">
                <mat-icon>arrow_forward_ios</mat-icon>
              </button>
              <ion-slides *ngIf="answers" (ionSlideWillChange)="slideChanged()" #slides pager="true"
                [options]="slideOpts">
                <ion-slide>
                  <div id="spiderChart">
                    <app-spider-chart *ngIf="answers" [data]="answers">
                    </app-spider-chart>
                  </div>
                </ion-slide>
                <ion-slide>
                  <div id="barChart">
                    <app-basic-bar style="width: 100%" *ngIf="answers" [categories]="barChartCategories"
                      title="basicChartTitle" [chartValues]="barData"></app-basic-bar>
                  </div>
                </ion-slide>
                <ion-slide
                  *ngFor="let cat of ['socialAffective','cognitive','language','motor','somatic']; let i = index">
                  <div [id]="'subBarChart'+i">
                    <app-basic-bar style="width: 100%" *ngIf="answers" [colors]="categoriesSettings[cat].colors"
                      [categories]="categoriesSettings[cat].legends"
                      title="catChartTitle"
                      [subCatParams]="{ domain: _translate.instant(cat), count: categoriesSettings[cat].subcats.length }"
                      [chartValues]="subCatBarData(cat)"></app-basic-bar>
                  </div>
                </ion-slide>
              </ion-slides>

              <br />
            </div>
          </mat-expansion-panel>
          <hr>
          <app-table [filteredQues]="filteredQues" [isDraft]="false" [isDetailView]="false" [observation]="obs"
            (answersChanged)="updateAnswers($event)">
          </app-table>
        </ion-col>
      </ion-row>
    </ion-col>
    <app-footer></app-footer>
  </ion-row>
</ion-content>