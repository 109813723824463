<div class="sticky" style="
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  ">
  <div style="
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
    ">
    <!-- Counter -->
    <div style="display: inline-block; position: relative">
      <mat-chip *ngIf="!isObsViewMode" style="background-color: transparent;" [selectable]="false">
        {{ "questionsAnswersCount" | translate:{answerCounter: answerCounter, questionCounter:questionCounter, noReply:
        questionCounter- answerCounter} | capitalizeFirstLetter}}
      </mat-chip>
      <mat-chip *ngIf="isObsViewMode" style="background-color: transparent;" [selectable]="false">
        {{ "questionsCount" | translate:{questionCounter:questionCounter} | capitalizeFirstLetter}}
      </mat-chip>
      <div style="
          position: absolute;
          width: 350px;
          box-sizing: border-box;
          left: 0px;
          top: 40px;
          z-index: 12;
        ">
      </div>
    </div>
  </div>
  <div style="
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex;
">
    <mat-chip-set>
      <!-- Age -->
      <div style="display: inline-block; position: relative">
        <mat-chip *ngIf="!isObsViewMode" [ngClass]="{ selected: isAgeChanged }" selectable="false"
          style="cursor: pointer" (click)="onFilter()">{{ min | age | async }} {{ max | age | async }}
          <mat-progress-spinner style="display: block; margin-left: auto; margin-right: auto;"
            *ngIf="isUpdateMinMaxLoading" [diameter]="24" [mode]="'indeterminate'" color="accent">
          </mat-progress-spinner>
          <mat-icon [ngClass]="{
              selected: isAgeChanged
            }" matChipRemove (click)="resetSelectedAge()" *ngIf="isAgeChanged">cancel</mat-icon>
        </mat-chip>
        <p style="margin: 0; font-weight: 500;" *ngIf="isObsViewMode">{{ 'observedPeriod' | translate |
          capitalizeFirstLetter }}: {{ min | age | async }} - {{ max | age | async }}</p>
      </div>
      <!-- Domaines -->
      <div style="display: inline-block; position: relative; margin-left: 8px;">
        <mat-chip style="cursor: pointer;"
          (click)="openDomainFiltrePanel()">
          {{ selectedCatTitle | translate | capitalizeFirstLetter }}
          <mat-icon matChipAvatar>arrow_drop_down</mat-icon>
        </mat-chip>
        <div style="
            position: absolute;
            width: fit-content;
            box-sizing: border-box;
            right: 0px;
            top: 40px;
            z-index: 12;
          ">
          <mat-expansion-panel style="min-width: 240px;" [expanded]="catFilterPanelState" (opened)="catFilterPanelState = true"
            (closed)="catFilterPanelState = false">
            <ion-row>
              <ion-col size="10">
                <div style="text-align: left">
                  <h2>{{ "domains" | translate | capitalizeFirstLetter}}</h2>
                </div>
              </ion-col>
              <ion-col size="2">
                <div style="text-align: right">
                  <button (click)="openDomainFiltrePanel()" mat-icon-button
                    aria-label="Example icon button with a vertical three dot icon">
                    <mat-icon>close</mat-icon>
                  </button>
                </div>
              </ion-col>
            </ion-row>

            <div>
              <div *ngFor="let c of catChoices; let i =index">
                <mat-checkbox [checked]="selectedCats.includes(i)" class="d-block"
                  (change)="onDomainChange(i, $event.checked)">
                  {{ c.label | translate | capitalizeFirstLetter}}
                </mat-checkbox>
              </div>
              <br />
            </div>
          </mat-expansion-panel>
        </div>
      </div>

      <!-- Questions -->
      <div style="display: inline-block; position: relative">
        <mat-chip [ngClass]="{
            red: questionFilter === 'red',
            selected: questionFilter !== 'all' && questionFilter !== 'red'
          }" style="cursor: pointer" (click)="openTypeFiltrePanel()">
          {{ selectedTypeTitle | translate }}
          <mat-icon trailingIcon matChipAvatar  *ngIf="questionFilter === 'all'">arrow_drop_down</mat-icon>
          <mat-icon [ngClass]="{
              red: questionFilter === 'red',
              selected: questionFilter !== 'all' && questionFilter !== 'red'
            }" matChipRemove (click)="resetSelectedType()" *ngIf="questionFilter !== 'all'">cancel</mat-icon>
        </mat-chip>
        <div style="
            position: absolute;
            width: fit-content;
            box-sizing: border-box;
            right: 0px;
            top: 40px;
            z-index: 12;
          ">
          <mat-expansion-panel style="min-width: 320px;" [expanded]="typeFiltrePanelState" (opened)="typeFiltrePanelState = true"
            (closed)="typeFiltrePanelState = false">
            <div style="text-align: right">
              <ion-row>
                <ion-col size="10">
                  <div style="text-align: left">
                    <h2>{{"questions" | translate | capitalizeFirstLetter}}</h2>
                  </div>
                </ion-col>
                <ion-col size="2">
                  <div style="text-align: right">
                    <button (click)="openTypeFiltrePanel()" mat-icon-button
                      aria-label="Example icon button with a vertical three dot icon">
                      <mat-icon>close</mat-icon>
                    </button>
                  </div>
                </ion-col>
              </ion-row>
            </div>
            <mat-radio-group [(ngModel)]="questionFilter" (ngModelChange)="onTypeChange($event)">
              <mat-radio-button style="margin: 2px" value="all">
                {{"displayAll" | translate | capitalizeFirstLetter}}
              </mat-radio-button>
              <br />
              <mat-radio-button style="margin: 2px" value="starting">
                {{"learningStart" | translate | capitalizeFirstLetter}}
              </mat-radio-button>
              <br />
              <mat-radio-button style="margin: 2px" value="until">
                {{"learningEnd" | translate | capitalizeFirstLetter}}
              </mat-radio-button>
              <br />
              <mat-radio-button style="margin: 2px" value="red">
                {{"learningLate" | translate | capitalizeFirstLetter}}
              </mat-radio-button>
              <mat-radio-button style="margin: 2px" value="green">
                {{"learningEarly" | translate | capitalizeFirstLetter}}
              </mat-radio-button>
              <br />
            </mat-radio-group>
          </mat-expansion-panel>
        </div>
      </div>

      <!-- Réponses -->
      <div style="display: inline-block; position: relative">
        <mat-chip [ngClass]="{
            selected: isObsViewMode ? (learningLevelFilter.length > 0) : answerFilter !== 'all'
          }" style="cursor: pointer" (click)="openRepFiltrePanel()">
          {{ selectedQuesRepTitle | translate }}
          <mat-icon matChipAvatar *ngIf="isObsViewMode ? (learningLevelFilter.length == 0) : answerFilter === 'all'">arrow_drop_down
          </mat-icon>
          <mat-icon [ngClass]="{
              selected: isObsViewMode ? (learningLevelFilter.length > 0) : answerFilter !== 'all'
            }" matChipRemove (click)="resetReponses()"
            *ngIf="isObsViewMode ? (learningLevelFilter.length > 0) : answerFilter !== 'all'">cancel</mat-icon>
        </mat-chip>
        <div style="
            position: absolute;
            width: fit-content;
            box-sizing: border-box;
            right: 0px;
            top: 40px;
            z-index: 12;
          ">
          <mat-expansion-panel style="min-width: 240px;" [expanded]="repFiltrePanelState" (opened)="repFiltrePanelState = true"
            (closed)="repFiltrePanelState = false">
            <div style="text-align: right; min-width: 240px;">
              <ion-row>
                <ion-col size="9">
                  <div style="text-align: left">
                    <h2>{{"answers" | translate | capitalizeFirstLetter}}</h2>
                  </div>
                </ion-col>
                <ion-col size="3">
                  <div style="text-align: right">
                    <button (click)="openRepFiltrePanel()" mat-icon-button
                      aria-label="Example icon button with a vertical three dot icon">
                      <mat-icon>close</mat-icon>
                    </button>
                  </div>
                </ion-col>
              </ion-row>
            </div>
            <div>
              <mat-radio-group [(ngModel)]="answerFilter" (ngModelChange)="filterQuestions()">
                <mat-radio-button *ngIf="!isObsViewMode" style="margin: 2px" value="all">
                  {{"displayAll" | translate | capitalizeFirstLetter}}
                </mat-radio-button>
                <br />
                <mat-radio-button style="margin: 2px" value="noRep" *ngIf="!isObsViewMode">
                  {{"withoutAnswer" | translate | capitalizeFirstLetter}}
                </mat-radio-button>
                <br />
                <mat-radio-button *ngIf="!isObsViewMode" style="margin: 2px" value="rep">
                  {{"learningStaging" | translate | capitalizeFirstLetter}}
                </mat-radio-button>
                <li *ngFor="let level of levelFilters; let i = index" style="margin-left: 10%; list-style: none">
                  <mat-checkbox [name]="level.name" [value]="level.value" [checked]="learningLevelFilter.includes(5-i)"
                    [color]="level.color" (change)="filtrePoints($event)">
                    {{ level.name | translate | capitalizeFirstLetter }}
                  </mat-checkbox>
                </li>
              </mat-radio-group>

              <br />
            </div>
          </mat-expansion-panel>
        </div>
      </div>
    </mat-chip-set>
  </div>
</div>
<br>