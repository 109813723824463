import { Component, OnInit } from '@angular/core';

import { LoadingController } from '@ionic/angular';
import { GraphqlService } from 'src/app/core/graphql/graphql.service';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/core/core.state';
import { selectRecordBirthdate, selectRecordId, selectRecordNumber } from 'src/app/core/record/record.selectors';
import { finalize, map, take } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from 'src/app/shared/services/shared.service';
@Component({
  templateUrl: './synthese.component.html',
  styleUrls: ['./synthese.component.scss'],
})
export class SyntheseComponent implements OnInit {
  isStart = true;

  isEnd = false;

  isVisible = false;

  isScrolled: boolean;

  graphData = undefined;

  recordId: string;

  earlyLateAnswers = {};

  recordNumber: number;

  isLoading = true;
  constructor(
    public loadingCtrl: LoadingController,
    private _graphql: GraphqlService,
    private _translate: TranslateService,
    private _store: Store<AppState>,
    private _sharedService: SharedService
  ) { }



  ngOnInit(): void {
    this._store.select(selectRecordId)
      .pipe(take(1))
      .subscribe(recordId => {
        this.recordId = recordId;
        this._graphql.getSyntheseData(recordId)
          .pipe(
            take(1),
            map(({ data }: any) => data),
            finalize(() => this.isLoading = false)
          ).subscribe(data => this.graphData = data)
        this._graphql.getRecordAnswers(this.recordId)
          .pipe(
            take(1),
            map(({ data }: any) => data.recordAnswers)
          ).subscribe(async (answers) => {

            this._store.select(selectRecordBirthdate)
              .pipe(take(1))
              .subscribe(birthdate => {
                const recordAge = this._sharedService.getAgeFromBirthdate(birthdate)
                //TODO: should be optimized by filtering on the backend instead of calling allanswers
                answers.forEach(answer => {
                  if (!(answer.observation.date in this.earlyLateAnswers)) {
                    this.earlyLateAnswers[answer.observation.date] = {
                      early: [],
                      late: []
                    }
                  }
                  if (answer.level < 5 && answer.question.direction == 'until' && answer.question.endMonth < (recordAge - this._sharedService.getAgeFromBirthdate(answer.observation.date))) {
                    this.earlyLateAnswers[answer.observation.date].late.push("QUESTIONS." + answer.answerCode + "_name")
                  }
                  else if (answer.level == 5 && answer.question.endMonth > (recordAge - this._sharedService.getAgeFromBirthdate(answer.observation.date))) {
                    this.earlyLateAnswers[answer.observation.date].early.push("QUESTIONS." + answer.answerCode + "_name")
                  }
                });
              })
          })
      })
    this._store.select(selectRecordNumber).pipe(take(1)).subscribe(number => this.recordNumber = number)

  }

  async onLoadingStarted() {
    if (!this.isVisible) {
      this.isVisible = true;
      const loading = await this.loadingCtrl.create({
        message: this._translate.instant("generatePDF"),
        duration: 5000,
      });
      await loading.present();
      loading.onDidDismiss().then(() => {
        this.isVisible = false;
      });
    }
  }

  onLoadingEnded() {
    this.loadingCtrl.dismiss();
  }

  onScroll(ev) {
    if (ev.detail.currentY > 50) {
      this.isScrolled = true;
    } else {
      this.isScrolled = false;
    }
  }

}
