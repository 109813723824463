<div style="text-align: center; padding-bottom: 32px; padding-top: 24px;">
  <h1 mat-dialog-title>{{ "observedPeriod" | translate | capitalizeFirstLetter}}</h1>
  <p>{{ "recordAge" | translate | capitalizeFirstLetter}} : {{ data.age | age | async }}</p>
  <hr>
  <div class="period-warning"><p>{{ 'periodWarning' | translate }}</p></div>
</div>

<mat-dialog-content>
  <ion-row>
    <ion-col>
      <mat-slide-toggle
        class="example-margin"
        color="accent"
        [(ngModel)]="isCustom"
      >
        {{ "manualSelection" | translate | capitalizeFirstLetter}}
      </mat-slide-toggle>
    </ion-col>
  </ion-row>

  <div style="text-align: center; padding: 16px" *ngIf="!isCustom">
    <mat-form-field color="accent" appearance="outline">
      <mat-label>{{ "period" | translate | capitalizeFirstLetter}}</mat-label>
      <mat-select [(value)]="selectedPeriod">
        <mat-optgroup *ngFor="let group of periodGroups" label="{{'REFS.'+group.name | translate}}">
          <mat-option *ngFor="let period of group.period" [value]="period">
            {{ period.viewValue }} {{ "years" | translate }}
          </mat-option>
        </mat-optgroup>
      </mat-select>
    </mat-form-field>
  </div>

  <div *ngIf="isCustom">
    <ion-row>
      <ion-col style="text-align: right; padding-right: 5px">
        <h3>{{ selected.lower | age | async }}</h3>
      </ion-col>
      <ion-col style="text-align: left; padding-left: 0px">
        <h3>- {{ selected.upper | age | async }}</h3>
      </ion-col>
      <!--     <ion-col style="text-align: center;">
        À {{ selected.upper | age | async }}
      </ion-col> -->
    </ion-row>
    <ion-item>
      <ion-range
        (ionChange)="onChange($event)"
        dual-knobs="true"
        [min]="0"
        [max]="348"
        [(value)]="selected"
        [step]="step"
        pin="true"
        snaps="true"
        ticks="true"
        mode="md"
        name="{{ observedPeriod | translate }}"
        class="age-range-slider"
      >
        <ion-label slot="start">0 {{'month' | translate }}</ion-label>
        <ion-label slot="end">{{ this.maxLength }}</ion-label>
      </ion-range>
    </ion-item>
  </div>
</mat-dialog-content>
<mat-dialog-actions style="align-items: end; justify-content: space-between">
  <div>
    <button mat-button (click)="init()" style="border-radius: 0px">
      {{ "reinit" | translate | capitalizeFirstLetter}}
    </button>
  </div>
  <span></span>

  <div>
    <button
      mat-button
      mat-dialog-close
      (click)="cancel()"
      style="border-radius: 0px"
    >
    {{ "cancel" | translate | capitalizeFirstLetter}}
    </button>
    <button
      mat-button
      [mat-dialog-close]="true"
      (click)="confirm()"
      cdkFocusInitial
      style="border-radius: 0px"
    >
    {{ "confirm" | translate | capitalizeFirstLetter}}
    </button>
  </div>
</mat-dialog-actions>
