import { createSelector } from '@ngrx/store';

import { selectRecordState } from '../core.state';
import { RecordState } from './record.model';



export const selectpendingObsId = createSelector(
  selectRecordState,
  (state: RecordState) => state.pendingObsId
);

export const selectValidatedObsIds = createSelector(
  selectRecordState,
  (state: RecordState) => state.validatedObsIds
);

export const selectRecordId = createSelector(
  selectRecordState,
  (state: RecordState) => state.record.id
);

export const selectRecordNumber = createSelector(
  selectRecordState,
  (state: RecordState) => state.record.recordNumber
);


export const selectRecordBirthdate = createSelector(
  selectRecordState,
  (state: RecordState) => state.record.birthdate
);

export const selectRecordGender = createSelector(
  selectRecordState,
  (state: RecordState) => state.record.gender
);
