import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-square-toc',
  templateUrl: './square-toc.component.html',
  styleUrls: ['./square-toc.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SquareTocComponent {
  @Input() content: any;

  @Input() filteredCats: Array<{label:string, value:string}>;


  @Input() isModal: boolean;

  constructor(
    public translate: TranslateService
  ) {}

  scrollToElement(content, cat: string) {
    const yOffset = document.getElementById(cat).offsetTop;
    content.scrollToPoint(0, yOffset - 120, 1000);
  }

  scrollTop(content) {
    content.scrollToTop(1000);
  }
}
