import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  OnDestroy,
  SimpleChanges,
} from '@angular/core';
import CATEGORIES from "src/assets/json/CATEGORIES.json";
import { ThemePalette } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { FiltreComponent } from '../../modals/filtre-age/filtre.component';
import * as lodash from 'lodash-es';
import { SharedService } from 'src/app/shared/services/shared.service';
import { finalize, map, take } from 'rxjs/operators';
import { GraphqlService } from 'src/app/core/graphql/graphql.service';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/core/core.state';
import { selectRecordBirthdate } from 'src/app/core/record/record.selectors';
import { obsRep } from 'src/app/shared/models/obsRep.model';


type QuestionFilterType = "all" | "starting" | "until" | "red" | "green"
type AnswerFilterType = "all" | "rep" | "noRep"

export interface LearningLevelFilter {
  value: number;
  name: string;
  color: ThemePalette;
}
@Component({
  selector: 'app-filtres',
  templateUrl: './filtres.component.html',
  styleUrls: ['./filtres.component.scss'],
})
export class FiltresComponent
  implements OnInit, OnDestroy {
  @Input() min: number | undefined;

  @Input() max: number;

  @Input() isObsViewMode = false;

  @Input() ageAtObs: number;

  @Input() obsId: string;

  @Input() date: any;

  @Input() learningLevelFilter: Array<number>;

  @Input() questionFilter: QuestionFilterType = 'all';

  @Input() answerFilter: AnswerFilterType;

  @Output() filteredQuesEvent = new EventEmitter();

  @Output() formFilledEvent = new EventEmitter<boolean>();

  @Input() answers: { [answerCode: string]: obsRep } = {};


  sansRep: number;

  age: number;

  repFiltrePanelState = false;

  catFilterPanelState = false;

  typeFiltrePanelState = false;

  resumePanelState = false;

  selectedCatTitle = 'domains';

  selectedQuesRepTitle = 'answers';

  selectedTypeTitle = 'questions';

  filteredQues = [];

  showOnlyAnswers = false;

  questionCounter = 0;

  filtersCache = {};

  isAgeChanged = false;

  catChoices = CATEGORIES;

  selectedCats = [0, 1, 2, 3, 4];

  isUpdateMinMaxLoading = false;
  questionsLoading = false;


  levelFilters: LearningLevelFilter[] = [
    {
      value: 5,
      name: 'assimilated',
      color: 'accent',
    },
    { value: 4, name: 'integrating', color: 'accent' },
    { value: 3, name: "exercising", color: 'accent' },
    {
      value: 2,
      name: 'nonAccess',
      color: 'accent',
    },
    {
      value: 1,
      name: 'unconcerned',
      color: 'accent',
    },
  ];

  constructor(
    public dialog: MatDialog,
    private sharedService: SharedService,
    private _graphql: GraphqlService,
    private _sharedService: SharedService,
    private _store: Store<AppState>,
  ) { }

  get answerCounter() {
    let count = 0;
    if (this.answers)
      count = Object.keys(this.answers).filter(answerCode => this.filteredQues.find(ques => ques.answerCode == answerCode)).length
    this.formFilledEvent.emit(this.questionCounter == count && count > 0)
    return count;
  }

  //first call to the filter is after the 'answers' input has been loaded
  ngOnChanges(changes: SimpleChanges) {
    if (!changes?.answers.previousValue && changes?.answers.currentValue) {
      this.filterQuestions();
    }


  }

  ngOnInit(): void {
    this._store.select(selectRecordBirthdate)
      .pipe(take(1))
      .subscribe(birthdate => this.age = this._sharedService.getAgeFromBirthdate(birthdate))

    if (this.min === undefined || this.max === undefined) {
      [this.min, this.max] = this.sharedService.determineMinMaxAge(this.age);
    }

    this.filtersCache = JSON.parse(localStorage.getItem('UMANWEB-SEARCHFILTERS')) || {};

    if (this.filtersCache !== null && (this.filtersCache[this.obsId])) {
      this.answerFilter = this.isObsViewMode ? 'rep' : this.filtersCache[this.obsId].answerFilter || 'all';//reponses
      this.questionFilter = this.filtersCache[this.obsId].questionFilter || 'all'; //questions
      this.learningLevelFilter = this.filtersCache[this.obsId].learningLevelFilter || [1, 2, 3, 4, 5]; //"Par étayage de l'apprentissage"
      this.selectedCats = this.filtersCache[this.obsId].selectedCsat || [0, 1, 2, 3, 4]

    } else {
      this.answerFilter = this.isObsViewMode ? 'rep' : 'all';
      this.questionFilter = 'all';
      this.learningLevelFilter = [1, 2, 3, 4, 5]
    }

    this.repFiltrePanelState = false;
    this.catFilterPanelState = false;
    this.typeFiltrePanelState = false;
    this.resumePanelState = false;

  }

  filterQuestions() {
    //Updating the cache
    this.filtersCache[this.obsId] = {
      answerFilter: this.answerFilter,
      questionFilter: this.questionFilter,
      learningLevelFilter: this.learningLevelFilter,
      selectedCats: this.selectedCats
    }
    localStorage.setItem('UMANWEB-SEARCHFILTERS', JSON.stringify(this.filtersCache));

    //mapping  categories to their value: 1 -> "A" etc
    const selectedCatValues = this.selectedCats.map(elem => this.catChoices[elem].value)




    this._graphql.getQuestions(this.obsId, this.ageAtObs, this.min, this.max, selectedCatValues, this.questionFilter, this.answerFilter, this.learningLevelFilter)
      .pipe(
        take(1),
        finalize(() => this.questionsLoading = false),
        map(({ data }: any) => data.observationQuestions)
      )
      .subscribe(questions => {
        this.filteredQues = questions

        this.questionCounter = this.filteredQues.length;
        //questions grouped by category and subcategory
        this.filteredQuesEvent.emit(Object.values(lodash.groupBy(this.filteredQues, (item) => item.category1Rk + item.category2Rk)))

      })
  }
  onFilter() {
    const dialogRef = this.dialog.open(FiltreComponent, {
      width: '80%',
      data: {
        min: this.min,
        max: this.max,
        age: this.age,
      },
    });

    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        if (data.data) {
          this.updateMinMax(data.min, data.max)
        }
      }
    });
  }

  updateMinMax(min: number, max: number) {
    this.isUpdateMinMaxLoading = true;
    this._graphql.updateMinMaxAge(this.obsId, min, max)
      .pipe(
        take(1),
        finalize(() => this.isUpdateMinMaxLoading = false)
      )
      .subscribe(() => {
        this.min = min;
        this.max = max;
        this.filterQuestions();
      })
  }

  resetSelectedAge() {
    this.updateMinMax(...this.sharedService.determineMinMaxAge(this.age));
  }

  resetSelectedCat() {
    this.selectedCats = [0, 1, 2, 3, 4];
    this.filterQuestions()
  }

  resetReponses() {
    this.answerFilter = this.isObsViewMode ? 'rep' : 'all';
    this.learningLevelFilter = [1, 2, 3, 4, 5];
    this.showOnlyAnswers = false;
    this.filterQuestions()
  }

  resetSelectedType() {
    this.questionFilter = 'all';
    this.selectedTypeTitle = 'Questions';
    this.filterQuestions()
  }

  openRepFiltrePanel() {
    this.catFilterPanelState = false;
    this.typeFiltrePanelState = false;
    this.resumePanelState = false;
    this.repFiltrePanelState = !this.repFiltrePanelState;
  }

  openDomainFiltrePanel() {
    this.repFiltrePanelState = false;
    this.typeFiltrePanelState = false;
    this.resumePanelState = false;
    this.catFilterPanelState = !this.catFilterPanelState;
  }

  openTypeFiltrePanel() {
    this.repFiltrePanelState = false;
    this.catFilterPanelState = false;
    this.resumePanelState = false;
    this.typeFiltrePanelState = !this.typeFiltrePanelState;
  }

  openResumePanel() {
    this.repFiltrePanelState = false;
    this.catFilterPanelState = false;
    this.typeFiltrePanelState = false;
    this.resumePanelState = !this.resumePanelState;
  }


  filtrePoints(c) {
    if (c.checked) {
      this.learningLevelFilter.push(c.source.value);
    } else {
      const index = this.learningLevelFilter.findIndex(
        (x) => x === c.source.value
      );
      if (index > -1) {
        this.learningLevelFilter.splice(index, 1);
      }
    }
    if (this.answerFilter == 'rep') {
      this.filterQuestions()
    }
  }

  onTypeChange(ev) {
    if (ev === 'all') {
      this.selectedTypeTitle = 'questions';
    } else if (ev === 'starting') {
      this.selectedTypeTitle = "learningStart";
    } else if (ev === 'until') {
      this.selectedTypeTitle = "learningEnd";
    } else if (ev === 'red') {
      this.selectedTypeTitle = 'learningLate';
    } else if (ev === 'green') {
      this.selectedTypeTitle = 'learningEarly';
    }
    this.filterQuestions();
  }

  onDomainChange(selectedIndex, checked) {

    if (checked) {
      this.selectedCats.push(selectedIndex)
    }
    else {
      this.selectedCats = this.selectedCats.filter(i => this.catChoices[i].value !== this.catChoices[selectedIndex].value)
    }

    this.filterQuestions();
  }

  ngOnDestroy() {
  }
}
