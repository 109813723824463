import {
  Component,
  Input,
  OnInit,
} from '@angular/core';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import domtoimage from 'dom-to-image';
import { formatDate } from '@angular/common';
import { AppState } from 'src/app/core/core.state';
import { Store } from '@ngrx/store';
import { selectRecordBirthdate, selectRecordId, selectRecordNumber } from 'src/app/core/record/record.selectors';
import {  finalize, map, take } from 'rxjs/operators';
import { SharedService } from 'src/app/shared/services/shared.service';
import { TranslateService } from '@ngx-translate/core';
import { GraphqlService } from 'src/app/core/graphql/graphql.service';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-pdf-synthese',
  templateUrl: './pdf-synthese.component.html',
  styleUrls: ['./pdf-synthese.component.scss'],
})
export class PDFSyntheseComponent implements OnInit {


  chartImage1: string = '';

  @Input() filteredQues = [];

  recordNumber: number;
  recordId: string;

  ageDossier: any;

  pdfLoading = false;

  constructor(
    private _sharedService: SharedService,
    private _translate: TranslateService,
    private _graphql: GraphqlService,
    private _store: Store<AppState>
  ) { }
  ngOnInit(): void {
    this._store.select(selectRecordBirthdate)
      .pipe(take(1))
      .subscribe(async birthdate => this.ageDossier = this._sharedService.getAgeFromBirthdate(birthdate))
    this._store.select(selectRecordNumber)
      .pipe(take(1))
      .subscribe(number => this.recordNumber = number)
    this._store.select(selectRecordId)
      .pipe(take(1))
      .subscribe(id => this.recordId = id)
  }


  start() {
    this.pdfLoading = true;
    const node1 = document.getElementById('highchart1');

    domtoimage
      .toPng(node1)
      .then((dataUrl: string) => {
        this.chartImage1 = dataUrl;
        this.generatePDF();
      })
      .catch((error: string) => {
        this.pdfLoading = false;
        console.error('oops, something went wrong!', error);
      });

  }

  generatePDF() {
    const earlyLateSkillsBody = []

    this._graphql.getRecordAnswers(this.recordId)
      .pipe(
        take(1),
        finalize(()=> this.pdfLoading = false),
        map(({ data }: any) => data.recordAnswers)
      ).subscribe(async (answers) => {
        const answersByObs = {};

        //TODO: should be optimized by filtering on the backend instead of calling allanswers
        answers.forEach(answer => {
          if (!(answer.observation.date in answersByObs)) {
            answersByObs[answer.observation.date] = {
              early: [],
              late: []
            }
          }
          if ( answer.level < 5 && answer.question.direction == 'until' && answer.question.endMonth < (this.ageDossier - this._sharedService.getAgeFromBirthdate(answer.observation.date))) {
            answersByObs[answer.observation.date].late.push(this._translate.instant("QUESTIONS." + answer.answerCode + "_name"))
          }
          else if ( answer.level == 5 && answer.question.endMonth > (this.ageDossier - this._sharedService.getAgeFromBirthdate(answer.observation.date))) {
            answersByObs[answer.observation.date].early.push(this._translate.instant("QUESTIONS." + answer.answerCode + "_name"))
          }
        });
        Object.keys(answersByObs).sort().forEach(dateObs => {

          earlyLateSkillsBody.push([
            {
              text: this._translate.instant("viewObsTitle") + formatDate(dateObs, 'longDate', 'fr'),
              alignment: 'left',
              fontSize: '14',
              bold: true,
              border: [false, false, false, false],
              margin: [8, 8, 0, 0],
            },
          ]);
          if (answersByObs[dateObs].early.length > 0) {
            earlyLateSkillsBody.push([
              {
                text: this._translate.instant("learningEarlyCount"),
                alignment: 'left',
                fontSize: '12',
                bold: false,
                color: '#55c40c',
                border: [false, false, false, false],
                margin: [8, 8, 0, 0],
              },
            ])
            answersByObs[dateObs].early.forEach((ques, index) => {
              earlyLateSkillsBody.push([
                {
                  text: `${index + 1}. ` + ques,
                  border: [false, false, false, false],
                }
              ])
            });
          }
          if (answersByObs[dateObs].late.length > 0) {
            earlyLateSkillsBody.push([
              {
                text: this._translate.instant("learningLateCount"),
                alignment: 'left',
                fontSize: '12',
                bold: false,
                color: '#b51c1c',
                border: [false, false, false, false],
                margin: [8, 8, 0, 0],
              },
            ])
            answersByObs[dateObs].late.forEach((ques, index) => {
              earlyLateSkillsBody.push([
                {
                  text: `${index + 1}. ` + ques,
                  border: [false, false, false, false],
                }
              ])
            });
          }
        })


        const logo = `
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M28.8146 35.1429L28.8146 14.5839H31.6443L35.427 25.4697H35.4847L39.2385 14.5839H42.0971L42.0971 35.1429H39.1518L39.1518 22.64H39.0941L36.1777 31.4757L34.7051 31.4757L31.8176 22.64H31.7598L31.7598 35.1429H28.8146Z" fill="black"/>
    <path d="M15.629 14.5839L15.629 30.5229C15.629 31.1966 15.5039 31.8222 15.2537 32.3997C15.0227 32.958 14.6858 33.4585 14.243 33.9012C13.8003 34.344 13.2902 34.6905 12.7127 34.9407C12.1352 35.191 11.5192 35.3161 10.8647 35.3161C10.2102 35.3161 9.59417 35.191 9.01667 34.9407C8.45842 34.6905 7.95792 34.344 7.51517 33.9012C7.07242 33.4585 6.72592 32.958 6.47567 32.3997C6.22542 31.8222 6.10029 31.1966 6.10029 30.5229L6.10029 14.5839H9.04554L9.04554 30.2341C9.04554 30.9656 9.21879 31.5046 9.56529 31.8511C9.91179 32.1976 10.3449 32.3709 10.8647 32.3709C11.3844 32.3709 11.8175 32.1976 12.164 31.8511C12.5105 31.5046 12.6838 30.9656 12.6838 30.2341L12.6838 14.5839L15.629 14.5839Z" fill="black"/>
    <line x1="22.6607" y1="11.1429" x2="22.6607" y2="40.2857" stroke="black" stroke-width="0.75"/>
    </svg>
    `;

        const pdf = {
          info: {
            title: `${this._translate.instant("record")} ${this.recordNumber} - Synthèse`,
            author: 'TODO',
            creator: 'UmanWeb',
          },
          pageMargins: [40, 16, 40, 40],
          footer: function (currentPage, pageCount) {
            return [
              {
                text: 'Page ' + currentPage.toString() + ' / ' + pageCount,
                alignment: 'center',
              },
            ];
          },
          pageSize: 'A4',
          pageOrientation: 'portrait',

          content: [
            {
              table: {
                widths: [150, 200, 150],
                body: [
                  [
                    {
                      rowSpan: 4,
                      svg: logo,
                      border: [false, false, false, false],
                    },
                    {
                      text: '',
                      border: [false, false, false, false],
                    },
                    {
                      text: '',
                      border: [false, false, false, false],
                    },
                  ],
                  [
                    {
                      text: '',
                      border: [false, false, false, false],
                    },
                    {
                      text: '',
                      border: [false, false, false, false],
                    },
                    {
                      text: this._translate.instant("fullname"),
                      border: [false, false, false, false],
                    },
                  ],
                  [
                    {
                      text: '',
                      border: [false, false, false, false],
                    },
                    {
                      text: `${this._translate.instant("record")} ${this.recordNumber}`,
                      alignment: 'center',
                      fontSize: '16',
                      bold: false,
                      margin: [0, 0, 0, 0],
                      border: [false, false, false, false],
                    },
                    {
                      text: ' ',
                      border: [false, false, false, false],
                    },
                  ],
                  [
                    {
                      text: '',
                      border: [false, false, false, false],
                    },
                    {
                      text: {
                        text: this._translate.instant("synthesis"),
                        alignment: 'center',
                        fontSize: '14',
                        bold: false,
                        margin: [0, 0, 0, 20],
                      },
                      border: [false, false, false, false],
                    },
                    {
                      text: ' ',
                      border: [false, false, false, false],
                    },
                  ],
                ],
              },
            },
            this.chartImage1 == '' ? '' : {
              columns: [
                {
                  margin: [200, 20, 0, 0],
                  width: 500,
                  height: 250,
                  alignment: 'center',
                  image: this.chartImage1,
                }
              ],
            },
            {
              table: {
                widths: [500],
                heights: [32, 232],
                body: [
                  [
                    {
                      text: this._translate.instant("notes") + ' :',
                      alignment: 'left',
                      fontSize: '12',
                      bold: false,
                      border: [true, true, true, false],
                      margin: [8, 8, 0, 0],
                    },
                  ],
                  [
                    {
                      text: ' ',
                      alignment: 'center',
                      fontSize: '12',
                      border: [true, false, true, true],
                      margin: [0, 0, 0, 0],
                    },
                  ],
                ],
              },
              border: [true, true, true, true],
              margin: [0, 16, 0, 0],
            },
            {
              table: {
                widths: [500],
                body: earlyLateSkillsBody
              },
              border: [true, true, true, true],
              margin: [0, 16, 0, 0],
            },
          ],
          defaultStyle: {
            font: 'BarlowSemiCondensed',
            fontSize: 10,
          },
          styles: {
            tableHeader: {
              fontSize: 8,
              color: '#757575',
              alignment: 'center',
              bold: true,
            },
            cat1Title: {
              fontSize: 16,
              alignment: 'left',
              bold: false,
            },
            cat2Title: {
              fontSize: 12,
              alignment: 'left',
              bold: false,
            },
            answer: {
              alignment: 'center',
              fontSize: 8,
              margin: 5,
            },
          },
        };

        pdfMake.fonts = {
          BarlowSemiCondensed: {
            normal: `https://db.onlinewebfonts.com/t/b34370616f8c4fa5d043a5e674b78a02.ttf`,
            bold: `https://db.onlinewebfonts.com/t/b7299979c1b5f55ee14ca767fd1290ab.ttf`
          },
        };

        (await pdfMake
          .createPdf(pdf))
          .download(pdf.info.title);

        this.chartImage1 = '';
      });
  }
}
