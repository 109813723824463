import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DraftModeComponent } from './observations/draft-mode/draft-mode.component';
import { SyntheseComponent } from './observations/synthese/synthese.component';
import { ViewModeComponent } from './observations/view-mode/view-mode.component';
import { DossierComponent } from './record.component';

const routes: Routes = [
  { path: '', component: DossierComponent },
  { path: 'draft/:obsId', component: DraftModeComponent },
  { path: 'observation/:obsId', component: ViewModeComponent },
  { path: 'synthese', component: SyntheseComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DossierRoutingModule {}
